import { addCollection } from "@iconify/vue"
let _initialized = false
export function init() {
  if (_initialized)
    return
  const collections = [
  {
    "prefix": "mdi",
    "icons": {
      "play": {
        "width": 24,
        "height": 24,
        "body": "<path fill=\"currentColor\" d=\"M8 5.14v14l11-7z\"/>"
      },
      "pause": {
        "width": 24,
        "height": 24,
        "body": "<path fill=\"currentColor\" d=\"M14 19h4V5h-4M6 19h4V5H6z\"/>"
      }
    }
  },
  {
    "prefix": "material-symbols",
    "icons": {
      "settings-rounded": {
        "width": 24,
        "height": 24,
        "body": "<path fill=\"currentColor\" d=\"M10.825 22q-.675 0-1.162-.45t-.588-1.1L8.85 18.8q-.325-.125-.612-.3t-.563-.375l-1.55.65q-.625.275-1.25.05t-.975-.8l-1.175-2.05q-.35-.575-.2-1.225t.675-1.075l1.325-1Q4.5 12.5 4.5 12.337v-.675q0-.162.025-.337l-1.325-1Q2.675 9.9 2.525 9.25t.2-1.225L3.9 5.975q.35-.575.975-.8t1.25.05l1.55.65q.275-.2.575-.375t.6-.3l.225-1.65q.1-.65.588-1.1T10.825 2h2.35q.675 0 1.163.45t.587 1.1l.225 1.65q.325.125.613.3t.562.375l1.55-.65q.625-.275 1.25-.05t.975.8l1.175 2.05q.35.575.2 1.225t-.675 1.075l-1.325 1q.025.175.025.338v.674q0 .163-.05.338l1.325 1q.525.425.675 1.075t-.2 1.225l-1.2 2.05q-.35.575-.975.8t-1.25-.05l-1.5-.65q-.275.2-.575.375t-.6.3l-.225 1.65q-.1.65-.587 1.1t-1.163.45zm1.225-6.5q1.45 0 2.475-1.025T15.55 12t-1.025-2.475T12.05 8.5q-1.475 0-2.488 1.025T8.55 12t1.013 2.475T12.05 15.5\"/>"
      },
      "arrow-back": {
        "width": 24,
        "height": 24,
        "body": "<path fill=\"currentColor\" d=\"m7.825 13l5.6 5.6L12 20l-8-8l8-8l1.425 1.4l-5.6 5.6H20v2z\"/>"
      },
      "close": {
        "width": 24,
        "height": 24,
        "body": "<path fill=\"currentColor\" d=\"M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z\"/>"
      },
      "bookmarks": {
        "width": 24,
        "height": 24,
        "body": "<path fill=\"currentColor\" d=\"M4 22V8q0-.825.588-1.412T6 6h8q.825 0 1.413.588T16 8v14l-6-3zm14-4V4H7V2h11q.825 0 1.413.588T20 4v14z\"/>"
      },
      "bookmark-add": {
        "width": 24,
        "height": 24,
        "body": "<path fill=\"currentColor\" d=\"M5 21V5q0-.825.588-1.412T7 3h7q-.5.75-.75 1.438T13 6q0 1.8 1.138 3.175T17 10.9q.575.075 1 .075t1-.075V21l-7-3zM17 9V7h-2V5h2V3h2v2h2v2h-2v2z\"/>"
      },
      "bookmark-remove": {
        "width": 24,
        "height": 24,
        "body": "<path fill=\"currentColor\" d=\"M21 7h-6V5h6zM5 21V5q0-.825.588-1.412T7 3h7q-.5.75-.75 1.438T13 6q0 1.8 1.138 3.175T17 10.9q.575.075 1 .075t1-.075V21l-7-3z\"/>"
      }
    }
  }
]
  for (const collection of collections) {
    addCollection(collection)
  }
  _initialized = true
}